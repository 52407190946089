.container {
  margin-bottom: 1rem;
}

.container :global(.TableCell__header) {
  background-color: var(--ux-white);
  padding-bottom: 0.25rem;
}

.container :global(.TableCell__header:last-child) {
  width: 100%;
}
