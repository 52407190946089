.emailButtonContainer {
  text-align: right;
  margin: var(--synth-spacing-3) 0 var(--synth-spacing-2);
}

.emailText {
  border: 1px solid black;
  border-radius: 1%;
  padding: var(--synth-spacing-2);

  p:last-of-type {
    margin-bottom: 0;
  }
}


