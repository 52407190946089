@mixin card-box-shadow($vOffset: .125rem, $bottomRadius: true, $topRadius: true) {
  border: none;
  box-shadow: 0 $vOffset .25rem rgba(0,0,0,10%);

  @if ($bottomRadius) {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  @if ($topRadius) {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}

@mixin card-container($maxWidth: $card-width) {
  width: calc(100% - #{$card-xs-spacing});

  @include media-breakpoint-up(sm) {
    max-width: $maxWidth;
    width: calc(100% - 2rem);
  }
}

@mixin card-spacing($margin: true, $padding: true) {
  @if ($margin) {
    margin: $card-xs-spacing auto;

    @include media-breakpoint-up(sm) {
      margin: $card-sm-spacing auto;
    }
  }

  @if ($padding) {
    padding: $card-xs-spacing;

    @include media-breakpoint-up(sm) {
      padding: $card-sm-spacing;
    }
  }
}
